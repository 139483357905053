<template>
  <section class="invoice-add-wrapper">
    <b-card
      no-body
      class="invoice-preview-card"
    >
      <!-- Header -->
      <b-card-body class="invoice-padding">

        <div class="d-flex justify-content-between flex-md-row flex-column invoice-spacing mt-0">

          <!-- Header: Left Content -->
          <div v-if="invoice !== undefined">
            <b-card-text class="mb-25">
              {{ invoice.seller.name }}
            </b-card-text>
            <b-card-text class="mb-25">
              {{ invoice.seller.adres }}
            </b-card-text>
            <b-card-text class="mb-0">
              {{ invoice.seller.nip }}
            </b-card-text>
          </div>

          <!-- Header: Right Content -->
          <div class="invoice-number-date mt-md-0 mt-2">
            <div class="d-flex align-items-center justify-content-md-end mb-1">
              <h4 class="invoice-title">
                Faktura #{{ invoiceNumber }}
              </h4>
            </div>
            <div v-if="invoice !== undefined" class="d-flex align-items-center mb-1">
              <span class="title">
                Data sprzedaży:
              </span>
              {{ invoice.sale_date }}
            </div>
          </div>
        </div>
      </b-card-body>

      <!-- Spacer -->
      <hr class="invoice-spacing">

      <!-- Invoice Client & Payment Details -->
      <b-card-body
        class="invoice-padding pt-0"
      >
        <b-row class="invoice-spacing">

          <!-- Col: Invoice To -->
          <b-col
            cols="12"
            xl="6"
            class="mb-lg-1"
          >
            <h6 class="mb-2">
              Odbiorca:
            </h6>

            <!-- Selected Client -->
            <div
              v-if="invoice !== undefined"
              class="mt-1"
            >
              <h6 class="mb-25">
                {{ invoice.customer.name }}
              </h6>
              <b-card-text class="mb-25">
                {{ invoice.customer.adres }}
              </b-card-text>
              <b-card-text class="mb-25">
                {{ invoice.customer.nip }}
              </b-card-text>
            </div>
          </b-col>

          <!-- Col: Payment Details -->
          <b-col
            xl="6"
            cols="12"
            class="mt-xl-0 mt-2 justify-content-end d-xl-flex d-block"
          >
            <div>
              <h6 class="mb-2">
                Szczegóły płatności:
              </h6>
              <table>
                <tbody
                  v-if="invoice !== undefined"
                >
                  <tr>
                    <td class="pr-1">
                      Nazwa banku:
                    </td>
                    <td>American Bank</td>
                  </tr>
                  <tr>
                    <td class="pr-1">
                      Kraj:
                    </td>
                    <td>Polska</td>
                  </tr>
                  <tr>
                    <td class="pr-1">
                      IBAN
                    </td>
                    <td>
                      {{ invoice.account_number }}
                    </td>
                  </tr>
                  <tr>
                    <td class="pr-1">
                      SWIFT:
                    </td>
                    <td>BR91905</td>
                  </tr>
                  <tr class="mt-1">
                    <td class="pr-1">
                      Sposób zapłaty
                    </td>
                    <td>
                      {{ invoice.payment_method }}
                    </td>
                  </tr>
                  <tr class="mt-1">
                    <td class="pr-1">
                      Waluta
                    </td>
                    <td>
                      {{ invoice.currency }}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </b-col>
        </b-row>
      </b-card-body>

      <!-- Items Section -->
      <b-card-body class="invoice-padding form-item-section">
        <div
          ref="form"
          class="repeater-form"
        >
          <b-row
            ref="row"
            class="pb-2"
          >

            <!-- Item Form -->
            <!-- ? This will be in loop => So consider below markup for single item -->
            <b-col cols="12">

              <!-- ? Flex to keep separate width for XIcon and SettingsIcon -->
              <div class="d-none d-lg-flex">
                <b-row class="flex-grow-1 px-1">
                  <!-- Single Item Form Headers -->
                  <b-col

                    cols="12"
                    lg="3"
                  >
                    Nazwa towaru lub usługi
                  </b-col>
                  <b-col

                    cols="12"
                    lg="1"
                  >
                    GTU
                  </b-col>
                  <b-col

                    cols="12"
                    lg="1"
                  >
                    Ilość
                  </b-col>
                  <b-col

                    cols="12"
                    lg="2"
                  >
                    Wartość netto
                  </b-col>
                  <b-col

                    cols="12"
                    lg="2"
                  >
                    Rabat
                  </b-col>
                  <b-col

                    cols="12"
                    lg="1"
                  >
                    VAT
                  </b-col>
                  <b-col
                    cols="12"
                    lg="2"
                  >
                    Wartość brutto
                  </b-col>
                </b-row>
                <div class="form-item-action-col" />
              </div>

              <div v-if="invoice !== undefined">
                <div
                  v-for="(item, index) in invoice.goods_services"
                  :key="index"
                  class="d-flex"
                >
                  <b-row class="flex-grow-1 p-2">
                    <!-- Single Item Form Headers -->
                    <b-col
                      cols="12"
                      lg="3"
                    >
                      {{ item.name }}
                    </b-col>

                    <b-col
                      cols="12"
                      lg="1"
                    >
                      {{ item.GTU }}
                    </b-col>

                    <b-col
                      cols="12"
                      lg="1"
                    >
                      {{ item.quantity }}
                    </b-col>

                    <b-col
                      cols="12"
                      lg="2"
                    >
                      {{ item.netto }}
                    </b-col>

                    <b-col
                      cols="12"
                      lg="2"
                    >
                      {{ item.discount }}
                    </b-col>

                    <b-col
                      cols="12"
                      lg="1"
                    >
                      {{ item.vat }}
                    </b-col>

                    <b-col
                      v-if="item.brutto_value !== undefined"
                      cols="12"
                      lg="2"
                    >
                      {{ item.brutto_value.toLocaleString('us', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) }}
                    </b-col>
                  </b-row>
                </div>
              </div>
            </b-col>
          </b-row>
        </div>
      </b-card-body>

      <!-- Invoice Description: Total -->
      <b-card-body class="invoice-padding pb-0">
        <b-row>

          <b-col
            cols="12"
            class="mt-3"
          />
          <!-- Col: Total -->
          <b-col
            cols="12"
            class="text-right"
          >
            <div class="invoice-total-wrapper">
              <div class="invoice-total-item">
                <p>
                  <span class="font-weight-bolder">Cena netto:</span>
                  {{ nettoValue }} PLN
                </p>
              </div>
              <div class="invoice-total-item">
                <p>
                  <span class="font-weight-bolder">Zniżka:</span>
                  {{ discount }} PLN
                </p>
              </div>
              <div class="invoice-total-item">
                <p>
                  <span class="font-weight-bolder">Po zniżce:</span>
                  {{ nettoAfterDiscount }} PLN
                </p>
              </div>
              <div class="invoice-total-item">
                <p>
                  <span class="font-weight-bolder">VAT:</span>
                  {{ taxValue }} %
                </p>
              </div>
              <hr class="my-50">
              <div
                v-if="bruttoValue !== undefined"
                class="invoice-total-item"
              >
                <p>
                  <span class="font-weight-bolder">Razem:</span>
                  {{ bruttoValue.toLocaleString('us', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) }} PLN
                </p>
              </div>
            </div>
          </b-col>
        </b-row>
      </b-card-body>
    </b-card>
  </section>
</template>

<script>
import {
  BRow, BCol, BCard, BCardBody, BCardText,
} from 'bootstrap-vue'

export default {
  name: 'VatInvoicePdf',
  components: {
    BRow, BCol, BCard, BCardBody, BCardText,
  },
  props: ['invoice', 'invoiceNumber', 'nettoValue', 'nettoAfterDiscount', 'taxValue', 'bruttoValue', 'discount'],
}
</script>
