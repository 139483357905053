<template>
  <section class="invoice-add-wrapper">
	<validation-observer
	  ref="form"
	  #default="{invalid}"
	>
    <b-form @submit.prevent>
      <b-row class="invoice-add">
        <!-- Col: Left (Invoice Container) -->
        <b-col
          cols="12"
          xl="9"
          md="8"
        >
          <b-card
            no-body
            class="invoice-preview-card"
          >
            <!-- Header -->
            <b-card-body class="invoice-padding pb-0">

              <div class="d-flex justify-content-between flex-md-row flex-column invoice-spacing mt-0">

                <!-- Header: Left Content -->
                <div>
                  <div class="logo-wrapper">
                    <logo />
                    <h3 class="text-primary invoice-logo">
                      Vuexy
                    </h3>
                  </div>
                  <b-card-text class="mb-25">
                    Office 149, 450 South Brand Brooklyn
                  </b-card-text>
                  <b-card-text class="mb-25">
                    San Diego County, CA 91905, USA
                  </b-card-text>
                  <b-card-text class="mb-0">
                    +1 (123) 456 7891, +44 (876) 543 2198
                  </b-card-text>
                </div>

                <!-- Header: Right Content -->
                <div class="invoice-number-date mt-md-0 mt-2">
                  <div class="d-flex align-items-center justify-content-md-end mb-1">
                    <h4 class="invoice-title">
                      Invoice
                    </h4>
                    <b-input-group class="input-group-merge invoice-edit-input-group disabled">
                      <b-input-group-prepend is-text>
                        <feather-icon icon="HashIcon" />
                      </b-input-group-prepend>
                      <b-form-input
                        id="invoice-data-id"
                        v-model="invoiceData.id"
                        disabled
                      />
                    </b-input-group>
                  </div>
                  <div class="d-flex align-items-center mb-1">
                    <span class="title">
                      Date:
                    </span>
                    <flat-pickr
                      v-model="invoiceData.issuedDate"
                      class="form-control invoice-edit-input"
                      :disabled="disabled"
                    />
                  </div>
                  <div class="d-flex align-items-center">
                    <span class="title">
                      Due Date:
                    </span>
                    <flat-pickr
                      v-model="invoiceData.dueDate"
                      class="form-control invoice-edit-input"
                      :disabled="disabled"
                    />
                  </div>
                </div>
              </div>
            </b-card-body>

            <!-- Spacer -->
            <hr class="invoice-spacing">

            <!-- Invoice Client & Payment Details -->
            <b-card-body
              class="invoice-padding pt-0"
            >

              <b-row class="invoice-spacing">

                <!-- waluta -->
                <b-col
                  cols="12"
                  xl="3"
                  class="mb-lg-1"
                >
                  <h6 class="mb-2">
                    Waluta:
                  </h6>
                <validation-provider
                  #default="{ errors }"
                  name="Waluta"
                  vid="Waluta"
                  rules="required"
                >
                  <v-select
                    v-model="invoiceData.paymentCurrency"
                    :state="errors.length > 0 ? false:null"
                    :options="paymentCurrency"
                    :disabled="disabled"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
				</validation-provider>
                </b-col>

                <!-- termin płatności -->
                <b-col
                  cols="12"
                  xl="3"
                  class="mb-lg-1"
                >
                  <h6 class="mb-2">
                    Sposób płatności
                  </h6>
                <validation-provider
                  #default="{ errors }"
                  name="Sposób płatności"
                  vid="Sposób płatności"
                  rules="required"
                >
                  <v-select
                    v-model="invoiceData.paymentMethod"
                    :state="errors.length > 0 ? false:null"
                    :options="paymentMethods"
                    :disabled="disabled"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
				</validation-provider>
                </b-col>

              </b-row>

              <b-row class="invoice-spacing">

                <!-- Col: Invoice To -->
                <b-col
                  cols="12"
                  xl="6"
                  class="mb-lg-1"
                >
                  <h6 class="mb-2">
                    Klient:
                  </h6>

                  <!-- Select Client -->
                <validation-provider
                  #default="{ errors }"
                  name="Klient"
                  vid="Klient"
                  rules="required"
                >
                  <v-select
                    v-model="invoiceData.client"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="clients"
                    label="company"
                    input-id="invoice-data-client"
                    :state="errors.length > 0 ? false:null"
                    :clearable="false"
                    :disabled="disabled"
                  >
                    <template #list-header>
                      <li
                        v-b-toggle.sidebar-invoice-add-new-customer
                        class="add-new-client-header d-flex align-items-center my-50"
                      >
                        <feather-icon
                          icon="PlusIcon"
                          size="16"
                        />
                        <span class="align-middle ml-25">Add New Customer</span>
                      </li>
                    </template>
                  </v-select>
                  <small class="text-danger">{{ errors[0] }}</small>
				</validation-provider>

                  <!-- Selected Client -->
                  <div
                    v-if="invoiceData.client"
                    class="mt-1"
                  >
                    <h6 class="mb-25">
                      {{ invoiceData.client.name }}
                    </h6>
                    <b-card-text class="mb-25">
                      {{ invoiceData.client.company }}
                    </b-card-text>
                    <b-card-text class="mb-25">
                      {{ invoiceData.client.address }}, {{ invoiceData.client.country }}
                    </b-card-text>
                    <b-card-text class="mb-25">
                      {{ invoiceData.client.contact }}
                    </b-card-text>
                    <b-card-text class="mb-0">
                      {{ invoiceData.client.companyEmail }}
                    </b-card-text>
                  </div>
                </b-col>

                <!-- Col: Payment Details -->
                <b-col
                  xl="6"
                  cols="12"
                  class="mt-xl-0 mt-2 justify-content-end d-xl-flex d-block"
                >
                  <div>
                    <h6 class="mb-2">
                      Payment Details:
                    </h6>
                    <table>
                      <tbody>
                        <tr>
                          <td class="pr-1">
                            Total Due:
                          </td>
                          <td><span class="font-weight-bold">$12,110.55</span></td>
                        </tr>
                        <tr>
                          <td class="pr-1">
                            Bank name:
                          </td>
                          <td>American Bank</td>
                        </tr>
                        <tr>
                          <td class="pr-1">
                            Country:
                          </td>
                          <td>United States</td>
                        </tr>
                        <tr>
                          <td class="pr-1">
                            IBAN:
                          </td>
                          <td>ETD95476213874685</td>
                        </tr>
                        <tr>
                          <td class="pr-1">
                            SWIFT code:
                          </td>
                          <td>BR91905</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </b-col>
              </b-row>
            </b-card-body>

            <!-- Invoice Description: Total -->
            <b-card-body class="invoice-padding pb-0">
              <b-row>

                <!-- Col: Sales Persion -->
                <b-col
                  cols="12"
                  md="4"
                  class="mt-md-0 mt-3 d-flex align-items-center"
                  order="2"
                  order-md="1"
                >
                  <label
                    for="invoice-data-sales-person"
                    class="text-nowrap mr-50"
                  >Name:</label>
                <validation-provider
                  #default="{ errors }"
                  name="Name"
                  vid="Name"
                  rules="required"
                >
                  <b-form-input
                    id="invoice-data-name"
                    v-model="invoiceData.name"
                    :state="errors.length > 0 ? false:null"
                    placeholder="Name"
                    :disabled="disabled"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
				</validation-provider>
                </b-col>
                <b-col
                  cols="12"
                  md="4"
                  class="mt-md-0 mt-3 d-flex align-items-center"
                  order="2"
                  order-md="1"
                >
                  <label
                    for="invoice-data-sales-person"
                    class="text-nowrap mr-50"
                  >Kwota netto:</label>
                <validation-provider
                  #default="{ errors }"
                  name="Kwota netto"
                  vid="Kwota netto"
                  rules="required"
                >
                  <b-form-input
                    id="invoice-data-netto"
                    v-model="invoiceData.netto"
                    :state="errors.length > 0 ? false:null"
                    placeholder="0.00"
                    :disabled="disabled"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
				</validation-provider>
                </b-col>
                <b-col
                  cols="12"
                  md="4"
                  class="mt-md-0 mt-3 d-flex align-items-center"
                  order="2"
                  order-md="1"
                >
                  <label
                    for="invoice-data-sales-person"
                    class="text-nowrap mr-50"
                  >Vat:</label>
                <validation-provider
                  #default="{ errors }"
                  name="Vat"
                  vid="Vat"
                  rules="required"
                >
                  <b-form-input
                    id="invoice-data-vat"
                    v-model="invoiceData.vat"
                    :state="errors.length > 0 ? false:null"
                    placeholder="0"
                    :disabled="disabled"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
				</validation-provider>
                </b-col>
              </b-row>
            </b-card-body>

            <!-- Invoice Description: Total -->
            <b-card-body class="invoice-padding pb-0">
              <b-row>

                <!-- Col: Sales Persion -->
                <b-col
                  cols="12"
                  md="6"
                  class="mt-md-0 mt-3 d-flex align-items-center"
                  order="2"
                  order-md="1"
                >
                  <label
                    for="invoice-data-sales-person"
                    class="text-nowrap mr-50"
                  >Sales Person:</label>
                <validation-provider
                  #default="{ errors }"
                  name="Sales Person"
                  vid="Sales Person"
                  rules="required"
                >
                  <b-form-input
                    id="invoice-data-sales-person"
                    v-model="invoiceData.salesPerson"
                    :state="errors.length > 0 ? false:null"
                    placeholder="Edward Crowley"
                    :disabled="disabled"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
				</validation-provider>
                </b-col>

                <!-- Col: Total -->
                <b-col
                  cols="12"
                  md="6"
                  class="mt-md-6 d-flex justify-content-end"
                  order="1"
                  order-md="2"
                >
                  <div class="invoice-total-wrapper">
                    <div class="invoice-total-item">
                      <p class="invoice-total-title">
                        Subtotal:
                      </p>
                      <p class="invoice-total-amount">
                        $1800
                      </p>
                    </div>
                    <div class="invoice-total-item">
                      <p class="invoice-total-title">
                        Discount:
                      </p>
                      <p class="invoice-total-amount">
                        $28
                      </p>
                    </div>
                    <div class="invoice-total-item">
                      <p class="invoice-total-title">
                        Tax:
                      </p>
                      <p class="invoice-total-amount">
                        21%
                      </p>
                    </div>
                    <hr class="my-50">
                    <div class="invoice-total-item">
                      <p class="invoice-total-title">
                        Total:
                      </p>
                      <p class="invoice-total-amount">
                        $1690
                      </p>
                    </div>
                  </div>
                </b-col>
              </b-row>
            </b-card-body>

            <!-- Spacer -->
            <hr class="invoice-spacing">

            <!-- Note -->
            <b-card-body class="invoice-padding pt-0">
              <span class="font-weight-bold">Note: </span>
              <b-form-textarea
                v-model="invoiceData.note"
                :disabled="disabled"
              />
            </b-card-body>

            <b-card-body class="invoice-padding pt-0">
              <span class="font-weight-bold">Invoice VAT: </span>
              <b-table
                :items="items"
                :fields="fields"
                responsive
                primary-key="id"
                show-empty
                empty-text="No matching records found"
                class="position-relative"
              >
                <!-- Column: Actions -->
                <template #cell(actions)="data">

                  <div class="text-nowrap">

                    <feather-icon
                      :id="`invoice-row-${data.item.id}-preview-icon`"
                      icon="EyeIcon"
                      size="16"
                      class="mx-1"
                      @click="$router.push({ name: 'apps-invoice-preview', params: { id: data.item.id, type: 'Invoice_Vat' }})"
                    />
                  </div>
                </template>

              </b-table>
            </b-card-body>
          </b-card>
        </b-col>

        <!-- Right Col: Card -->
        <b-col
          cols="12"
          md="4"
          xl="3"
          class="invoice-actions mt-md-0 mt-2"
        >

          <!-- Action Buttons -->
          <b-card>

            <!-- Button: Send Invoice -->
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mb-75"
              block
              disabled
            >
              Send Invoice
            </b-button>

            <!-- Button: DOwnload -->
            <b-button
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              variant="outline-primary"
              class="mb-75"
              block
              :disabled="disabled"
            >
              Preview
            </b-button>

            <!-- Button: Print -->
            <b-button
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              variant="outline-primary"
              @click="onSubmit()"
              block
              :disabled="disabled"
            >
              Save
            </b-button>
          </b-card>

          <!-- Status-->

          <b-form-group
            label="Status"
            label-for="payment-method"
          >
            <v-select
              v-model="invoiceData.invoiceStatus"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="invoiceStatus"
              input-id="payment-method"
              class="payment-selector"
              :clearable="false"
              :disabled="disabled"
            />
          </b-form-group>

          <!-- Język -->

          <b-form-group
            label="Język"
            label-for="payment-method"
          >
            <v-select
              v-model="invoiceData.invoiceLanguage"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="invoiceLanguage"
              input-id="payment-method"
              class="payment-selector"
              :clearable="false"
              :disabled="disabled"
            />
          </b-form-group>

          <!-- Wystawiaj cyklicznie co :  -->

          <b-form-group
            label="Wysatwiaj cyklicznie co :"
            label-for="payment-period"
          >
            <v-select
              v-model="invoiceData.invoicePeriod"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="invoicePeriod"
              input-id="invoicePeriod"
              class="payment-selector"
              :clearable="false"
              :disabled="disabled"
            />
          </b-form-group>

          <!-- ? Below values are not adding invoiceData to keep invoiceData more generic and less confusing  -->

          <!-- Client Notes -->
          <div class="d-flex justify-content-between align-items-center my-1">
            <label for="clientNotes">Client Notes</label>
            <b-form-checkbox
              id="clientNotes"
              :checked="false"
              switch
              :disabled="disabled"
            />
          </div>

          <!-- Automatyczna windykacja -->
          <div class="d-flex justify-content-between align-items-center">
            <label for="patymentTerms">Automatyczna windykacja</label>
            <b-form-checkbox
              id="patymentTerms"
              :checked="true"
              switch
              :disabled="disabled"
            />
          </div>

          <!-- Faktura do paragonu -->
          <div class="d-flex justify-content-between align-items-center mt-1">
            <label for="">Faktura do paragonu</label>
            <b-form-checkbox
              id=""
              :checked="true"
              switch
              :disabled="disabled"
            />
          </div>

          <!-- Mechanizm podzielonej płatności -->
          <div class="d-flex justify-content-between align-items-center mt-1">
            <label for="">Mechanizm podzielonej płatności</label>
            <b-form-checkbox
              id=""
              :checked="false"
              switch
              :disabled="disabled"
            />
          </div>
        </b-col>
      </b-row>
    </b-form>
    </validation-observer>
    <invoice-sidebar-add-new-customer />
  </section>
</template>

<script>
import Logo from '@core/layouts/components/Logo.vue'
import { ref, onUnmounted } from '@vue/composition-api'
import { heightTransition } from '@core/mixins/ui/transition'
import Ripple from 'vue-ripple-directive'
// eslint-disable-next-line import/no-cycle
import store from '@/store'
import {
  BRow,
  BCol,
  BCard,
  BCardBody,
  BButton,
  BTable,
  BCardText,
  BForm,
  BFormGroup,
  BFormInput,
  BInputGroup,
  BInputGroupPrepend,
  BFormTextarea,
  BFormCheckbox,
  VBToggle,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import flatPickr from 'vue-flatpickr-component'
import axios from 'axios'
import Swal from 'sweetalert2'
import axiosIns from '@/libs/axios'
import {
  paymentMethods, invoiceStatus, invoicePeriod, invoiceLanguage, paymentTimePeriod, paymentCurrency,
} from '@/views/apps/invoice/invoiceDictionaries'
import invoiceStoreModule from '../invoiceStoreModule'
import InvoiceSidebarAddNewCustomer from '../InvoiceSidebarAddNewCustomer.vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required, min } from '@validations'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardBody,
    BButton,
    BTable,
    BCardText,
    BForm,
    BFormGroup,
    BFormInput,
    BInputGroup,
    BInputGroupPrepend,
    BFormTextarea,
    BFormCheckbox,
    flatPickr,
    vSelect,
    Logo,
    InvoiceSidebarAddNewCustomer,
    ValidationProvider,
    ValidationObserver,

  },
  directives: {
    Ripple,
    'b-toggle': VBToggle,

  },
  mixins: [heightTransition],
  props: ['disabled'],
  data() {
    return {
      invoice: {},
      fields: [
        {
          key: 'number',
          label: 'Numer',
          sortable: true,
        },
        {
          key: 'actions',
        },
      ],
      items: [],
      paymentMethods,
      invoiceStatus,
      invoicePeriod,
      invoiceLanguage,
      paymentTimePeriod,
      paymentCurrency,
    }
  },
  mounted() {
    this.initTrHeight()
    this.getInvoice()
  },
  created() {
    window.addEventListener('resize', this.initTrHeight)
  },
  destroyed() {
    window.removeEventListener('resize', this.initTrHeight)
  },
  methods: {
    addNewItemInItemForm() {
      this.$refs.form.style.overflow = 'hidden'
      this.invoiceData.items.push(JSON.parse(JSON.stringify(this.itemFormBlankItem)))

      this.$nextTick(() => {
        this.trAddHeight(this.$refs.row[0].offsetHeight)
        setTimeout(() => {
          this.$refs.form.style.overflow = null
        }, 350)
      })
    },
    removeItem(index) {
      this.invoiceData.items.splice(index, 1)
      this.trTrimHeight(this.$refs.row[0].offsetHeight)
    },
    initTrHeight() {
      this.trSetHeight(null)
      this.$nextTick(() => {
        if (typeof this.$refs.form !== 'undefined') this.trSetHeight(this.$refs.form.scrollHeight)
      })
    },
    getInvoice() {
      if (typeof this.$route.params.id !== 'undefined') {
        const invoiceId = this.$route.params.id
        axiosIns.get(`InvoiceAdvancePayment/${invoiceId}`).then(response => {
          this.invoice = response.data
          if (typeof this.invoice.invoice_prepayments !== 'undefined' && typeof this.invoice.invoice_prepayments.advance_payment !== 'undefined') {
            this.invoiceData.client = this.invoice.invoice_prepayments.seller
            this.invoiceData.name = this.invoice.invoice_prepayments.advance_payment.name
            this.invoiceData.netto = this.invoice.invoice_prepayments.advance_payment.netto
            this.invoiceData.vat = this.invoice.invoice_prepayments.advance_payment.vat
            if (typeof this.invoice.invoice_vat_id !== 'undefined') {
              this.items.push({ id: this.invoice.invoice_vat_id, number: this.invoice.invoice_vat_number })
            }
          }
        })
      }
    },
    onSubmit() {
      this.$refs.form.validate().then(success => {
        if (success) {
      const data = {
        seller: {
          name: this.invoiceData.client.name,
          adres: (typeof this.invoiceData.client.address !== 'undefined' ? this.invoiceData.client.address : this.invoice.invoice_prepayments.seller.adres),
          nip: this.invoiceData.client.nip,
        },
        customer: {
          name: 'Mariusz',
          adres: 'Office 149, 450 South Brand Brooklyn',
          nip: '1234567890',
        },
        advance_payment: {
          name: this.invoiceData.name,
          netto: parseFloat(this.invoiceData.netto),
          vat: parseInt(this.invoiceData.vat),
        },
        account_number: 'ETD95476213874685',
        payment_method: this.invoiceData.paymentMethod,
        currency: this.invoiceData.paymentCurrency,
        type: 'Advance_Payment',
      }

      const config = { headers: { Authorization: `Bearer ${localStorage.getItem('accessToken')}` } }

      if (typeof this.$route.params.id !== 'undefined') {
        axios.put(`${process.env.VUE_APP_API_URL}InvoiceAdvancePayment/${this.$route.params.id}`, data, config).then(() => {
          Swal.fire(
            'Pomyślnie zaktualizowano Fakturę',
            '',
            'success',
          )
        }).catch(() => {
          Swal.fire(
            'Coś poszło nie tak!',
            '',
            'error',
          )
        })
      } else {
        axiosIns.post('InvoiceAdvancePayment', data, config).then(() => {
          Swal.fire(
            'Pomyślnie dodano Fakturę',
            '',
            'success',
          )
        }).catch(() => {
          Swal.fire(
            'Coś poszło nie tak!',
            '',
            'error',
          )
        })
      }
        }
      })
    },
  },
  setup() {
    const INVOICE_APP_STORE_MODULE_NAME = 'app-invoice'

    // Register module
    if (!store.hasModule(INVOICE_APP_STORE_MODULE_NAME)) store.registerModule(INVOICE_APP_STORE_MODULE_NAME, invoiceStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(INVOICE_APP_STORE_MODULE_NAME)) store.unregisterModule(INVOICE_APP_STORE_MODULE_NAME)
    })

    const clients = ref([])
    store.dispatch('app-invoice/fetchClients')
      .then(response => {
        clients.value = response.data
      })

    const itemFormBlankItem = {
      item: null,
      cost: 0,
      gross: 0,
      cost_vat: 0,
      qty: 0,
      description: '',
      vat: 0,
    }

    const invoiceData = ref({
      id: 5037,
      client: null,

      name: '',
      netto: 0.00,
      vat: 0,
      note: 'It was a pleasure working with you and your team. We hope you will keep us in mind for future freelance projects. Thank You!',
      paymentMethod: null,
      paymentCurrency: '',
    })

    const itemsOptions = [
      {
        itemTitle: 'App Design',
        cost: 24,
        qty: 1,
        description: 'Designed UI kit & app pages.',
      },
      {
        itemTitle: 'App Customization',
        cost: 26,
        qty: 1,
        description: 'Customization & Bug Fixes.',
      },
      {
        itemTitle: 'ABC Template',
        cost: 28,
        qty: 1,
        description: 'Bootstrap 4 admin template.',
      },
      {
        itemTitle: 'App Development',
        cost: 32,
        qty: 1,
        description: 'Native App Development.',
      },
    ]

    return {
      invoiceData,
      clients,
      itemsOptions,
      itemFormBlankItem,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';

.invoice-add-wrapper {
  .add-new-client-header {
    padding: $options-padding-y $options-padding-x;
    color: $success;

    &:hover {
      background-color: rgba($success, 0.12);
    }
  }
}
</style>

<style lang="scss" scoped>
@import "~@core/scss/base/pages/app-invoice.scss";
@import '~@core/scss/base/components/variables-dark';

.form-item-section {
  background-color: $product-details-bg;
}

.form-item-action-col {
  width: 27px;
}

.repeater-form {
  // overflow: hidden;
  transition: .35s height;
}

.v-select {
  &.item-selector-title,
  &.payment-selector {
    background-color: #fff;

    .dark-layout & {
      background-color: unset;
    }
  }
}

.dark-layout {
  .form-item-section {
    background-color: $theme-dark-body-bg;

    .row .border {
      background-color: $theme-dark-card-bg;
    }

  }
}
</style>
