<template>
  <div>
    <VatInvoicePreview v-if="this.$route.params.type === 'Invoice_Vat'" />
    <AdvancePayment v-if="this.$route.params.type === 'Invoice_Prepayment'" />
    <Proforma v-if="this.$route.params.type === 'Invoice_Proforma'" />
    <BankStatement
      v-if="$route.params.type === 'bank-statement'"
      :edit="true"
    />
  </div>
</template>

<script>
import Logo from '@core/layouts/components/Logo.vue'
import { heightTransition } from '@core/mixins/ui/transition'
import Ripple from 'vue-ripple-directive'
import {
  BRow, BCol, BCard, BCardBody, BButton, BCardText, BForm, BFormGroup, BFormInput, BInputGroup, BInputGroupPrepend, BFormTextarea, BFormCheckbox, BPopover, BAlert, BLink, VBToggle,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import flatPickr from 'vue-flatpickr-component'
import axios from 'axios'
import VatInvoiceEdit from '@/views/apps/invoice/invoice-edit/VatInvoiceEdit.vue'
import AdvancePayment from '@/views/apps/invoice/invoice-types/AdvancePayment.vue'
import VatInvoicePreview from '@/views/apps/invoice/invoice-preview/VatInvoicePreview.vue'
import AdvancePaymentPreview from '@/views/apps/invoice/invoice-preview/AdvancePaymentPreview.vue'
import InvoiceSidebarSendInvoice from '../InvoiceSidebarSendInvoice.vue'
import InvoiceSidebarAddPayment from '../InvoiceSidebarAddPayment.vue'
import BankStatement from '../invoice-types/BankStatement.vue'

export default {
  components: {
    AdvancePaymentPreview,
    VatInvoicePreview,
    AdvancePayment,
    VatInvoiceEdit,
    BRow,
    BCol,
    BCard,
    BCardBody,
    BButton,
    BCardText,
    BForm,
    BFormGroup,
    BFormInput,
    BInputGroup,
    BInputGroupPrepend,
    BFormTextarea,
    BFormCheckbox,
    BPopover,
    BAlert,
    BLink,
    flatPickr,
    vSelect,
    Logo,
    InvoiceSidebarSendInvoice,
    InvoiceSidebarAddPayment,
    BankStatement,
  },
  directives: {
    Ripple,
    'b-toggle': VBToggle,
  },
  mixins: [heightTransition],
  data() {
    return {
      invoiceData: null,
      documentData: {},
    }
  },

  mounted() {
    if (this.$route.params.type !== 'bank-statement') {
      this.fetchInvoice()
    }
  },
  created() {
    window.addEventListener('resize', this.initTrHeight)
  },
  destroyed() {
    window.removeEventListener('resize', this.initTrHeight)
  },
  methods: {
    async fetchInvoice() {
      const config = { headers: { Authorization: `Bearer ${localStorage.getItem('accessToken')}` } }
      try {
        this.invoiceData = (await axios.get(`${process.env.VUE_APP_API_URL}Invoice/${this.$route.params.id}`, config)).data
        console.log(this.invoiceData)
      } catch (e) {
        console.log(e)
      }
    },
  },
  setup() {
    const paymentMethods = [
      'Bank Account',
      'PayPal',
      'UPI Transfer',
    ]

    const paymentCurrency = [
      'PLN',
      'EUR',

    ]

    return {
      paymentMethods,
      paymentCurrency,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>

<style lang="scss" scoped>
@import "~@core/scss/base/pages/app-invoice.scss";

.form-item-section {
background-color:$product-details-bg;
}

.form-item-action-col {
  width: 27px;
}

.repeater-form {
  // overflow: hidden;
  transition: .35s height;
}

.v-select {
  &.item-selector-title,
  &.payment-selector {
    background-color: #fff;

    .dark-layout & {
      background-color: unset;
    }
  }
}

.dark-layout {
  .form-item-section {
    background-color: $theme-dark-body-bg;

    .row .border {
      background-color: $theme-dark-card-bg;
    }

  }
}
</style>
