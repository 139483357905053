<template>
  <section class="invoice-add-wrapper">
    <b-row
      v-if="invoice"
      class="invoice-add"
    >

      <!-- Col: Left (Invoice Container) -->
      <b-col
        cols="12"
        xl="9"
        md="8"
      >
        <b-form @submit.prevent>
          <b-card
            no-body
            class="invoice-preview-card"
          >
            <!-- Header -->
            <b-card-body class="invoice-padding">

              <div class="d-flex justify-content-between flex-md-row flex-column invoice-spacing mt-0">

                <!-- Header: Left Content -->
                <div>
                  <div class="logo-wrapper">
                    <logo />
                    <h3 class="text-primary invoice-logo">
                      Vuexy
                    </h3>
                  </div>
                  <b-card-text class="mb-25">
                    <b-form-input
                      v-model="invoice.seller.name"
                    />
                  </b-card-text>
                  <b-card-text class="mb-25">
                    <b-form-input
                      v-model="invoice.seller.adres"
                    />
                  </b-card-text>
                  <b-card-text class="mb-0">
                    <b-form-input
                      v-model="invoice.seller.nip"
                    />
                  </b-card-text>
                </div>

                <!-- Header: Right Content -->
                <div class="invoice-number-date mt-md-0 mt-2">
                  <div class="d-flex align-items-center justify-content-md-end mb-1">
                    <h4 class="invoice-title">
                      Faktura
                    </h4>
                    <b-input-group class="input-group-merge invoice-edit-input-group disabled">
                      <b-input-group-prepend is-text>
                        <feather-icon icon="HashIcon" />
                      </b-input-group-prepend>
                      <b-form-input
                        id="invoice-data-id"
                        v-model="invoiceNumber"
                        disabled
                      />
                    </b-input-group>
                  </div>
                  <div class="d-flex align-items-center mb-1">
                    <span class="title">
                      Data sprzedaży:
                    </span>
                    <flat-pickr
                      v-model="invoice.sale_date"
                      class="form-control invoice-edit-input"
                    />
                  </div>
                </div>
              </div>
            </b-card-body>

            <!-- Spacer -->
            <hr class="invoice-spacing">

            <!-- Invoice Client & Payment Details -->
            <b-card-body
              class="invoice-padding pt-0"
            >
              <b-row class="invoice-spacing">

                <!-- Col: Invoice To -->
                <b-col
                  cols="12"
                  xl="6"
                  class="mb-lg-1"
                >
                  <h6 class="mb-2">
                    Odbiorca:
                  </h6>

                  <!-- Selected Client -->
                  <div
                    v-if="invoice.customer"
                    class="mt-1"
                  >
                    <h6 class="mb-25">
                      <b-form-input
                        v-model="invoice.customer.name"
                      />
                    </h6>
                    <b-card-text class="mb-25">
                      <b-form-input
                        v-model="invoice.customer.adres"
                      />
                    </b-card-text>
                    <b-card-text class="mb-25">
                      <b-form-input
                        v-model="invoice.customer.nip"
                      />
                    </b-card-text>
                  </div>
                </b-col>

                <!-- Col: Payment Details -->
                <b-col
                  xl="6"
                  cols="12"
                  class="mt-xl-0 mt-2 justify-content-end d-xl-flex d-block"
                >
                  <div>
                    <h6 class="mb-2">
                      Szczegóły płatności:
                    </h6>
                    <table>
                      <tbody>
                        <tr>
                          <td class="pr-1">
                            Nazwa banku:
                          </td>
                          <td>American Bank</td>
                        </tr>
                        <tr>
                          <td class="pr-1">
                            Kraj:
                          </td>
                          <td>Polska</td>
                        </tr>
                        <tr>
                          <td class="pr-1">
                            IBAN
                          </td>
                          <td>
                            <b-form-input
                              v-model="invoice.account_number"
                              type="number"
                            />
                          </td>
                        </tr>
                        <tr>
                          <td class="pr-1">
                            SWIFT:
                          </td>
                          <td>BR91905</td>
                        </tr>
                        <tr class="mt-1">
                          <td class="pr-1">
                            Sposób zapłaty
                          </td>
                          <td>
                            <b-form-select
                              v-model="invoice.payment_method"
                              :options="['Przelew']"
                            />
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </b-col>
              </b-row>
            </b-card-body>

            <!-- Items Section -->
            <b-card-body class="invoice-padding form-item-section">
              <div
                ref="form"
                class="repeater-form"
              >
                <b-row
                  ref="row"
                  class="pb-2"
                >

                  <!-- Item Form -->
                  <!-- ? This will be in loop => So consider below markup for single item -->
                  <b-col cols="12">

                    <!-- ? Flex to keep separate width for XIcon and SettingsIcon -->
                    <div class="d-none d-lg-flex">
                      <b-row class="flex-grow-1 px-1">
                        <!-- Single Item Form Headers -->
                        <b-col

                          cols="12"
                          lg="4"
                        >
                          Nazwa towaru lub usługi
                        </b-col>
                        <b-col

                          cols="12"
                          lg="1"
                        >
                          GTU
                        </b-col>
                        <b-col

                          cols="12"
                          lg="1"
                        >
                          Ilość
                        </b-col>
                        <b-col

                          cols="12"
                          lg="2"
                        >
                          Wartość netto
                        </b-col>
                        <b-col

                          cols="12"
                          lg="1"
                        >
                          Rabat
                        </b-col>
                        <b-col

                          cols="12"
                          lg="1"
                        >
                          VAT
                        </b-col>
                        <b-col
                          cols="12"
                          lg="2"
                        >
                          Wartość brutto
                        </b-col>
                      </b-row>
                      <div class="form-item-action-col" />
                    </div>

                    <div v-if="invoice !== null">
                      <div
                        v-for="(item, index) in invoice.goods_services"
                        :key="index"
                        class="d-flex"
                      >
                        <b-row class="flex-grow-1 p-2">
                          <!-- Single Item Form Headers -->
                          <b-col
                            cols="12"
                            lg="4"
                          >
                            <b-form-input
                              v-model="item.name"
                            />
                          </b-col>

                          <b-col
                            cols="12"
                            lg="1"
                          >
                            <b-form-input
                              v-model="item.GTU"
                            />
                          </b-col>

                          <b-col
                            cols="12"
                            lg="1"
                          >
                            <b-form-input
                              v-model="item.quantity"
                            />
                          </b-col>

                          <b-col
                            cols="12"
                            lg="2"
                          >
                            <b-form-input
                              v-model="item.netto"
                            />
                          </b-col>

                          <b-col
                            cols="12"
                            lg="1"
                          >
                            <b-form-input
                              v-model="item.discount"
                            />
                          </b-col>

                          <b-col
                            cols="12"
                            lg="1"
                          >
                            <b-form-input
                              v-model="item.vat"
                            />
                          </b-col>

                          <b-col
                            cols="12"
                            lg="2"
                          >
                            <b-form-input
                              v-model="item.brutto_value"
                            />
                          </b-col>
                        </b-row>
                      </div>
                    </div>
                  </b-col>
                </b-row>
              </div>
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                size="sm"
                variant="primary"
                @click="addNewItemInItemForm"
              >
                Dodaj nowy
              </b-button>
            </b-card-body>

            <!-- Invoice Description: Total -->
            <b-card-body class="invoice-padding pb-0">
              <b-row>

                <b-col
                  cols="12"
                  md="6"
                  class="mt-md-0 mt-3"
                  order="2"
                  order-md="1"
                />
                <!-- Col: Total -->
                <b-col
                  cols="12"
                  md="6"
                  class="mt-md-6 d-flex justify-content-end"
                  order="1"
                  order-md="2"
                >
                  <div class="invoice-total-wrapper">
                    <div class="invoice-total-item">
                      <p class="invoice-total-title">
                        Cena netto:
                      </p>
                      <p class="invoice-total-amount">
                        {{ nettoValue }} PLN
                      </p>
                    </div>
                    <div class="invoice-total-item">
                      <p class="invoice-total-title">
                        Zniżka:
                      </p>
                      <p class="invoice-total-amount">
                        {{ discount }} PLN
                      </p>
                    </div>
                    <div class="invoice-total-item">
                      <p class="invoice-total-title">
                        Po zniżce:
                      </p>
                      <p class="invoice-total-amount">
                        {{ nettoAfterDiscount }} PLN
                      </p>
                    </div>
                    <div class="invoice-total-item">
                      <p class="invoice-total-title">
                        VAT:
                      </p>
                      <p class="invoice-total-amount">
                        {{ taxValue }} %
                      </p>
                    </div>
                    <hr class="my-50">
                    <div class="invoice-total-item">
                      <p class="invoice-total-title">
                        Razem:
                      </p>
                      <p class="invoice-total-amount">
                        {{ bruttoValue }} PLN
                      </p>
                    </div>
                  </div>
                </b-col>
              </b-row>
            </b-card-body>
          </b-card>
        </b-form>
      </b-col>

      <!-- Right Col: Card -->
      <b-col
        cols="12"
        md="4"
        xl="3"
        class="invoice-actions"
      >

        <!-- Action Buttons -->
        <b-card>

          <!-- Button: Send Invoice -->
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            class="mb-75"
            block
            @click="updateInvoice"
          >
            Zaktualizuj fakturę VAT
          </b-button>
        </b-card>
      </b-col>
    </b-row>

    <invoice-sidebar-send-invoice />
    <invoice-sidebar-add-payment />
  </section>
</template>

<script>
import Logo from '@core/layouts/components/Logo.vue'
import { heightTransition } from '@core/mixins/ui/transition'
import Ripple from 'vue-ripple-directive'
import {
  BRow, BCol, BCard, BCardBody, BButton, BCardText, BForm, BFormInput, BInputGroup, BFormSelect, BInputGroupPrepend, VBToggle,
} from 'bootstrap-vue'
import flatPickr from 'vue-flatpickr-component'
import axios from 'axios'
import InvoiceSidebarSendInvoice from '../InvoiceSidebarSendInvoice.vue'
import InvoiceSidebarAddPayment from '../InvoiceSidebarAddPayment.vue'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardBody,
    BButton,
    BCardText,
    BForm,
    BFormInput,
    BInputGroup,
    BInputGroupPrepend,
    BFormSelect,
    flatPickr,
    Logo,
    InvoiceSidebarSendInvoice,
    InvoiceSidebarAddPayment,
  },
  directives: {
    Ripple,
    'b-toggle': VBToggle,
  },
  mixins: [heightTransition],
  data() {
    return {
      invoice: null,
      invoicePrepayments: [],
      invoiceNumber: '',
      invoiceReleaseDate: '',
      nettoValue: 0,
      nettoAfterDiscount: 0,
      taxValue: 0,
      bruttoValue: 0,
      discount: 0,
    }
  },

  mounted() {
    this.fetchInvoice()
  },
  created() {
    window.addEventListener('resize', this.initTrHeight)
  },
  destroyed() {
    window.removeEventListener('resize', this.initTrHeight)
  },
  methods: {
    addNewItemInItemForm() {
      this.invoice.goods_services.push({
        name: '',
        GTU: 0,
        quantity: 1,
        netto: 0,
        discount: 0,
        vat: 23,
        brutto_value: 0,
      })
    },
    async fetchInvoice() {
      const config = { headers: { Authorization: `Bearer ${localStorage.getItem('accessToken')}` } }
      try {
        const invoiceData = (await axios.get(`${process.env.VUE_APP_API_URL}Invoice/${this.$route.params.id}`, config)).data
        this.invoice = await invoiceData.invoice_vat
        this.invoicePrepayments = await invoiceData.invoice_prepayments
        this.invoiceNumber = await invoiceData.number
        this.invoiceReleaseDate = await invoiceData.release_date
        invoiceData.invoice_vat.goods_services.forEach(service => {
          this.nettoValue += service.netto
          this.nettoAfterDiscount += service.netto_after_discount
          this.bruttoValue += service.brutto_value
          this.discount += service.discount
          this.taxValue = service.vat
        })
      } catch (e) {
        console.log(e)
      }
    },
    async updateInvoice() {
      const config = { headers: { Authorization: `Bearer ${localStorage.getItem('accessToken')}` } }
      const putData = {
        seller: this.invoice.seller,
        customer: this.invoice.customer,
        goods_services: this.invoice.goods_services,
        account_number: this.invoice.account_number,
        payment_method: this.invoice.payment_method,
      }
      try {
        await axios.put(`${process.env.VUE_APP_API_URL}Invoice/${this.$route.params.id}`, putData, config)
        await this.fetchInvoice()
      } catch (e) {
        console.log(e)
      }
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>

<style lang="scss" scoped>
@import "~@core/scss/base/pages/app-invoice.scss";

.form-item-section {
  background-color:$product-details-bg;
}

.form-item-action-col {
  width: 27px;
}

.repeater-form {
  // overflow: hidden;
  transition: .35s height;
}

.v-select {
  &.item-selector-title,
  &.payment-selector {
    background-color: #fff;

    .dark-layout & {
      background-color: unset;
    }
  }
}

.dark-layout {
  .form-item-section {
    background-color: $theme-dark-body-bg;

    .row .border {
      background-color: $theme-dark-card-bg;
    }

  }
}
</style>
